const zh = {
  language: '语言',
  CustomerService: '客户服务',
  enterHk: '走进华康',
  IndustryInformation: "行业资讯",
  TempleOfHeavenSeries: '经典系列',
  merchantsJoin: "招商加盟",
  // 走进华康页面
  AboutHuakang: "关于华康",
  intHk1: '华康酒业专注研发以“华夏儿女幸福安康”为主线的文化品类收藏款白酒。民间有句谚语“无酒不成席”，而酒是人类生活中的主要饮品之一。',
  intHk2: '源远流长的中国酒文化在推动传统历史文化发展方面发挥着重要的作用，而文化创意产品作为宣传区域文化，民族文化的典型产品，在经济新常态下实现对酒文化的创意产品设计，有着宣传和发展的重要作用。同时华康酒业研发文创产品也是发展的必要途径之一。',
  CharacteristicsofHuakangLiquor: '华康酒特点',
  characteristic: '酱香型白酒的酿造工艺特殊，迥然不同于浓香和清香',
  title1: '酱香型酒易挥发物质少',
  titleTxt1: '酱香型白酒蒸馏时接酒温度高达 40 度以上，比其它酒接酒时的温度高出近一倍。高温下易挥发物质自然挥发掉的多，而且酱香型白酒要经三年以上的贮存，贮存损失高达2%以上，所以酒体中保存的易择发物质少。自然对人体的刺激少，有利于健康。',
  title2: '酱香酒的酚类化合物多',
  titleTxt2: '近年来，越来越多的消费者趋向于选择干红葡萄酒，原因在于干红葡萄酒含有较多的酚类化合物，有利于预防心血管疾病。酱香型白酒中的酚类化合物是其它名优白酒的3至4倍，可见酱香型白酒与干红葡萄酒有异曲同工之妙。',
  title3: '酱香酒酒精浓度科学合理',
  titleTxt3: '酱香型白酒的酒精浓度一般在 53%vol左右，而酒精浓度在53度时水分子和酒精分子缔合得最牢固。加之酱香酒的贮存期较长，游离的酒分子少，所以对身体的刺激小，有利于健康是不言而喻的。',
  title4: '酿造工艺特殊',
  titleTxt4: '一瓶酱香型白酒从原料进厂到产品出厂，至少要经过五年。在这当中，分两次投料、九次蒸煮、八次摊晾，并要加曲、高温堆积，入池发酵，取酒、贮存、勾兑等，在漫长、特殊而神秘的生物反应过程中，在酵池和空气中庞大的微生物族群的共同作用下，各种有益的微生物尽数罗至于酒体中。',
  joinUs: '加入我们',
  // 底部
  contact: "联系",
  address: '地址',
  addressDetail: '郑州市郑东新区农业东路宝龙广场33号',
  tel: '电话',
  email: '邮编',
  contacts: '联系人',
  contactsName: '尚先生',
  // 行业资讯页面
  indTitle1: "中信建投：白酒中长期改善回升的确定性较高",
  indTitleDet1: '【中国白酒网】中信建投指出，白酒中长期改善回升的确定性较高，2023年业绩及估值均有提升空间，并重申看多白酒的四大理由。',
  indTitle2: "兔年生肖酒上架 白酒文化消费正浓",
  indTitleDet2: '【中国白酒网】推出新年生肖酒是各酒企在岁末的重要举措之一。12月8日，记者了解到，截至目前，五粮液、泸州老窖、郎酒、茅台葡萄酒等酒企先后推出了兔年生肖酒。',
  indTitle3: "2023春糖增设酒水供应链专区",
  indTitleDet3: '【中国白酒网】全国糖酒会官微1月5日消息，第108届全国糖酒会将设置一展双馆，展览面积26.5万平方米，共包括九大展区、十七大专区，并增设预制菜专区及酒水供应链专区。',
  indTitle4: "多家酒企计划上市 下一个上市会是谁？",
  indTitleDet4: '【中国白酒网】众所周知，企业上市之后不仅可以更方便地融资，获得更高的收益，改善公司治理，而且可以大幅提升企业品牌形象与价值，特别是对于白酒这类生活消费品，白酒企业有没有上市存在较大的差别。',
  cancel: '取消',
  showTitle: '中信建投：白酒中长期改善回升的确定性较高',
  time: '时间',
  showDetail: '【中国白酒网】中信建投指出，白酒中长期改善回升的确定性较高，2023年业绩及估值均有提升空间，并重申看多白酒的四大理由。1）基本面春节可能超预期。2）春节酒企回款情况稳定，1季报有支撑。3）景气度回升，估值具备提升空间。4）宏观环境预期向好有利于外资回流。要重视白酒投资机遇，尤其是长线来看目前具备明显的上涨空间。首推高端贵州茅台、五粮液、泸州老窖，地产龙头古井贡酒、洋河股份、今世缘、山西汾酒、迎驾贡酒等。',
  author: '作者',
  authorName: '吴晓辉',
  source: "来源",
  sourceDetail: '证券时报网',
  more: '更 多',
  // 天坛系列页面
  HuakangWine: '华康酒',
  hkWineDetail1: '顾名思义是华夏儿女幸福安康的意思，华夏又是酒人的乐土，地不分东西南北，人不分男女老少，历经数千年而不衰。饮酒的意义远不止生理性消费，远不止口腹之乐；在许多场合，它都是作为一个文化符号，一种文化消费，用来表示一种礼仪，一种气氛，一种情趣，一种心境。这也是华康酒业研发收藏款白酒的意义所在。',
  hkWineDetail2: '华康酱香型白酒，其酒质晶亮透明，微有黄色，酱香突出，令人陶醉，敞杯不饮，香气扑鼻，开怀畅饮，满口生香，饮后空杯，留香更大，持久不散。口味幽雅细腻。酒体丰满醇厚，回味悠长，香味不绝。华康酒液具有纯净透明、醇馥幽郁的特点。',
  hkWineDetail3: '随着文化创意产品的蓬勃发展，文化创意已经成为未来提升设计价值的关键所在，将“文化”融入产品的设计已经是全球市场的设计趋势，华康酒带着美好的寓意，将中华文化融入酒文化里，共同参与文创产品的发展与宣传，在大家品鉴、欣赏的同时，以酒结友。',
  hkWineDetail4: '华康酒经典款，以中国的传统红色为主打色，瓶身流畅一体，文字和包装均以烫金工艺突出产品特点，线条感丰富，翻盖式的打开方式，更为时尚，符合大众审美特点。其价格亲民，性价比极高，作为主打经典款。',
  // 招商加盟
  ContactUs: "联系我们",
  copyright:'©2022版权所有华康集团豫ICP备2022023872号-2号',
  Tips:'您的浏览器不支持 video 标签。',
}
export default zh