const en = {
    language: 'language',
    CustomerService: 'Customer service',
    enterHk: 'Enter Huakang',
    IndustryInformation: "Industry information",
    TempleOfHeavenSeries: 'Classic collection',
    merchantsJoin: "JOIN US",
    // 走进华康页面
    AboutHuakang: "About Huakang",
    intHk1: 'Huakang Liquor Industry focuses on the research and development of cultural category collection Baijiu with the theme of "happiness and well-being of Chinese children". There is a folk saying that "no wine, no table", and wine is one of the main drinks in human life.',
    intHk2: 'The Chinese wine culture with a long history plays an important role in promoting the development of traditional history and culture. As a typical product of promoting regional culture and national culture, cultural creative products play an important role in promoting and developing the creative product design of wine culture under the new economic normal. At the same time, the research and development of cultural and creative products of Huakang Liquor is also one of the necessary ways for development.',
    CharacteristicsofHuakangLiquor: 'Characteristics of Huakang Liquor',
    characteristic: 'Maotai flavor Baijiu has a special brewing process, which is quite different from luzhou flavor and delicate flavor',
    title1: 'Less volatile substances in Maotai-flavor liquor',
    titleTxt1: 'During the distillation of Maotai flavor Baijiu, the temperature of liquor reception is more than 40 ℃, nearly twice as high as that of other liquors. Under high temperature, volatile substances naturally volatilize more, and Maotai flavor Baijiu needs to be stored for more than three years, and the storage loss is up to more than 2%, so the volatile substances stored in the liquor body are less. Nature has little stimulation to human body and is beneficial to health.',
    title2: 'There are many phenolic compounds in Maotai-flavor wine',
    titleTxt2: 'In recent years, more and more consumers tend to choose dry red wine, because dry red wine contains more phenolic compounds, which is conducive to the prevention of cardiovascular disease. The phenolic compounds in Maotai flavor Baijiu are three to four times that of other famous Baijiu, which shows that Maotai flavor Baijiu and dry red wine have similar similarities.',
    title3: 'The alcohol concentration of Maotai-flavor liquor is scientific and reasonable',
    titleTxt3: 'The alcohol concentration of Maotai flavor Baijiu is generally about 53% vol, and when the alcohol concentration is 53 degrees, water molecules and alcohol molecules are most firmly associated. In addition, Maotaixiang liquor has a long storage period and less free alcohol molecules, so it has little stimulation to the body and is beneficial to health.',
    title4: 'Special brewing technology',
    titleTxt4: 'It will take at least five years for a bottle of Maotai flavor Baijiu to enter the factory from raw materials to leave the factory. In this process, there are two times of feeding, nine times of cooking, eight times of spreading and drying, and addition of koji, high temperature accumulation, fermentation in the tank, wine taking, storage, blending, etc. In the long, special and mysterious biological reaction process, under the joint action of the fermentation tank and the huge microbial population in the air, all kinds of beneficial microorganisms are included in the wine body.',
    joinUs: 'Join us',
    // 底部
    contact: "contact",
    address: 'address',
    addressDetail: 'No. 33, Baolong Square, Nongyong East Road, Zhengdong New District, Zhengzhou City',
    tel: 'Telephone',
    email: 'Zip code',
    contacts: 'contacts',
    contactsName: 'Mr. Shang',
    // 行业资讯页面
    indTitle1: "CSCI: Baijiu has a high certainty of improvement and recovery in the medium and long term",
    indTitleDet1: '[Chinese Baijiu. com] CSCI pointed out that Baijiu has a high certainty of improvement and recovery in the medium and long term, and there is room for improvement in its performance and valuation in 2023, and reiterated the four reasons for being bullish on Baijiu.',
    indTitle2: "Baijiu of the Year of the Rabbit is on the shelves, and the cultural consumption of liquor is strong",
    indTitleDet2: "【 Chinese Baijiu 】 The launch of New Year's Zodiac Wine is one of the important measures taken by liquor companies at the end of the year. On December 8, the reporter learned that up to now, Wuliangye, Luzhou Laojiao, Langjiu, Maotai Wine and other wine companies have successively launched the Year of the Rabbit wine.",
    indTitle3: "2023 Spring Sugar will add a special area for liquor supply chain",
    indTitleDet3: '[Chinese Baijiu Website] On January 5, the official micro blog of the National Sugar and Alcohol Fair reported that the 108th National Sugar and Alcohol Fair will set up one exhibition and two pavilions, with an exhibition area of 265000 square meters, including nine exhibition areas, 17 special areas, and the addition of prefabricated vegetable area and wine supply chain area.',
    indTitle4: "Who will be the next liquor company to be listed?",
    indTitleDet4: '[Chinese Baijiu. com] As we all know, after listing, enterprises can not only more easily raise funds, obtain higher returns, improve corporate governance, but also significantly enhance the brand image and value of enterprises. Especially for consumer goods such as Baijiu, whether Baijiu enterprises are listed or not is quite different.',
    cancel: 'cancel',
    showTitle: 'CSCI: Baijiu has a high certainty of improvement and recovery in the medium and long term',
    time: 'time',
    showDetail: '[Chinese Baijiu. com] CSCI pointed out that Baijiu has a high certainty of improvement and recovery in the medium and long term, and there is room for improvement in its performance and valuation in 2023, and reiterated the four reasons for being bullish on Baijiu. 1) Fundamentals The Spring Festival may exceed expectations. 2) The payment collection of liquor enterprises during the Spring Festival was stable, and the quarterly report was supported. 3) The prosperity has rebounded and the valuation has room for improvement. 4) The expected improvement of the macro environment is conducive to the return of foreign capital. We should pay attention to the investment opportunities of Baijiu, especially in the long run. It is the first to promote high-end Kweichow Moutai, Wuliangye, Luzhou Laojiao, real estate leaders Gujing Gongjiu, Yanghe Shares, Jinshiyuan, Shanxi Fenjiu, Yingjia Gongjiu, etc.',
    author: 'author',
    authorName: 'Wu Xiaohui',
    source: "source",
    sourceDetail: 'Securities Times',
    more: 'MORE',
    // 天坛系列页面
    HuakangWine: 'Huakang wine',
    hkWineDetail1: "As the name implies, it means that Chinese people are happy and healthy. China is also a paradise for wine drinkers. It is located in the east, west, north and south, and men, women, old and young. It has survived for thousands of years. The significance of drinking is far more than physiological consumption, and far more than the pleasure of eating; On many occasions, it is used as a cultural symbol, a cultural consumption, to express a kind of etiquette, an atmosphere, an interest, and a mood. This is also the significance of Huakang's research and development of collection Baijiu.",
    hkWineDetail2: "Huakang Maotai flavor Baijiu is bright and transparent, slightly yellow, and the Maotai flavor is prominent, which makes people intoxicated. Open the cup and don't drink it. The aroma is pungent, cheerful, and full of fragrance. After drinking, the empty cup will leave more fragrance and last longer. The taste is elegant and delicate. The wine is full-bodied and full-bodied, with a long aftertaste and lingering fragrance. Huakang liquor has the characteristics of purity, transparency, mellow fragrance, and melancholy.",
    hkWineDetail3: 'With the vigorous development of cultural and creative products, cultural creativity has become the key to enhance the value of design in the future. The design trend of integrating "culture" into products has become a global market. Huakang Wine, with a beautiful implication, integrates Chinese culture into wine culture, and participates in the development and promotion of cultural and creative products, and makes friends with wine while tasting and appreciating.',
    hkWineDetail4: "The classic style of Huakang wine is mainly colored with Chinese traditional red, and the bottle body is smooth and integrated. The characters and packaging are all gilded to highlight the product characteristics, rich in line sense, and the flip style of opening, which is more fashionable and in line with the aesthetic characteristics of the public. Its price is close to the people, and its performance-price ratio is very high. It is the main classic style.",
    // 招商加盟
    ContactUs: "contact us",
    copyright:'© 2022 Copyright Huakang Group YuICPB No. 2022023872 - No. 2',
    Tips:'Your browser does not support video tags.',
}
export default en