const zhTW = {
    language: '語言',
    CustomerService: '客戶服務',
    enterHk: '走進華康',
    IndustryInformation: "行業資訊",
    TempleOfHeavenSeries: '經典系列',
    merchantsJoin: "招商加盟",
    // 走进华康页面
    AboutHuakang: "關於華康",
    intHk1: '華康酒業專注研發以“華夏兒女幸福安康”為主線的文化品類收藏款白酒。 民間有句諺語“無酒不成席”，而酒是人類生活中的主要飲品之一。',
    intHk2: '源遠流長的中國酒文化在推動傳統歷史文化發展方面發揮著重要的作用，而文化創意產品作為文宣區域文化，民族文化的典型產品，在經濟新常態下實現對酒文化的創意產品設計，有著文宣和發展的重要作用。 同時華康酒業研發文創產品也是發展的必要途徑之一。',
    CharacteristicsofHuakangLiquor: '華康酒特點',
    characteristic: '醬香型白酒的釀造工藝特殊，迥然不同於濃香和清香',
    title1: '醬香型酒易揮發物質少',
    titleTxt1: '醬香型白酒蒸餾時接酒溫度高達40度以上，比其它酒接酒時的溫度高出近一倍。 高溫下易揮發物質自然揮發掉的多，而且醬香型白酒要經三年以上的貯存，貯存損失高達2%以上，所以酒體中保存的易擇發物質少。 自然對人體的刺激少，有利於健康。',
    title2: '醬香酒的酚類化合物多',
    titleTxt2: '近年來，越來越多的消費者趨向於選擇幹紅葡萄酒，原因在於幹紅葡萄酒含有較多的酚類化合物，有利於預防心血管疾病。 醬香型白酒中的酚類化合物是其它名優白酒的3至4倍，可見醬香型白酒與幹紅葡萄酒有異曲同工之妙。',
    title3: '醬香酒酒精濃度科學合理',
    titleTxt3: '醬香型白酒的酒精濃度一般在53%vol左右，而酒精濃度在53度時水分子和酒精分子締合得最牢固。 加之醬香酒的貯存期較長，游離的酒分子少，所以對身體的刺激小，有利於健康是不言而喻的。',
    title4: '釀造工藝特殊',
    titleTxt4: '一瓶醬香型白酒從原料進廠到產品出廠，至少要經過五年。 在這當中，分兩次投料、九次蒸煮、八次攤晾，並要加曲、高溫堆積，入池發酵，取酒、貯存、勾兌等，在漫長、特殊而神秘的生物反應過程中，在酵池和空氣中龐大的微生物族群的共同作用下，各種有益的微生物盡數羅至於酒體中。',
    joinUs: '加入我們',
    // 底部
    contact: "聯繫",
    address: '地址',
    addressDetail: '鄭州市鄭東新區農業東路寶龍廣場33號',
    tel: '電話',
    email: '郵編',
    contacts: '連絡人',
    contactsName: '尚先生',
    // 行业资讯页面
    indTitle1: "中信建投：白酒中長期改善回升的確定性較高",
    indTitleDet1: '【中國白酒網】中信建投指出，白酒中長期改善回升的確定性較高，2023年業績及估值均有提升空間，並重申看多白酒的四大理由。',
    indTitle2: "兔年生肖酒上架白酒文化消費正濃",
    indTitleDet2: '【中國白酒網】推出新年生肖酒是各酒企在歲末的重要舉措之一。 12月8日，記者瞭解到，截至目前，五糧液、瀘州老窖、郎酒、茅臺葡萄酒等酒企先後推出了兔年生肖酒。',
    indTitle3: "2023春糖增設酒水供應鏈專區",
    indTitleDet3: '【中國白酒網】全國糖酒會官微1月5日消息，第108届全國糖酒會將設定一展雙館，展覽面積26.5萬平方米，共包括九大展區、十七大專區，並增設預製菜專區及酒水供應鏈專區。',
    indTitle4: "多家酒企計畫上市下一個上市會是誰？",
    indTitleDet4: '【中國白酒網】眾所周知，企業上市之後不僅可以更方便地融資，獲得更高的收益，改善公司治理，而且可以大幅提升企業品牌形象與價值，特別是對於白酒這類生活消費品，白酒企業有沒有上市存在較大的差別。',
    cancel: '取消',
    showTitle: '中信建投：白酒中長期改善回升的確定性較高',
    time: '時間',
    showDetail: '【中國白酒網】中信建投指出，白酒中長期改善回升的確定性較高，2023年業績及估值均有提升空間，並重申看多白酒的四大理由。 1）基本面春節可能超預期。 2）春節酒企回款情况穩定，1季報有支撐。 3）景氣度回升，估值具備提升空間。 4）宏觀環境預期向好有利於外資回流。 要重視白酒投資機遇，尤其是長線來看現時具備明顯的上漲空間。 首推高端貴州茅臺、五糧液、瀘州老窖，地產龍頭古井貢酒、洋河股份、今世緣、山西汾酒、迎駕貢酒等。',
    author: '作者',
    authorName: '吳曉輝',
    source: "來源",
    sourceDetail: '證券時報網',
    more: '更 多',
    // 天坛系列页面
    HuakangWine: '華康酒',
    hkWineDetail1: '顧名思義是華夏兒女幸福安康的意思，華夏又是酒人的樂土，地不分東西南北，人不分男女老少，歷經數千年而不衰。 飲酒的意義遠不止生理性消費，遠不止口腹之樂； 在許多場合，它都是作為一個文化符號，一種文化消費，用來表示一種禮儀，一種氣氛，一種情趣，一種心境。 這也是華康酒業研發收藏款白酒的意義所在。',
    hkWineDetail2: '華康醬香型白酒，其酒質晶亮透明，微有黃色，醬香突出，令人陶醉，敞杯不飲，香氣撲鼻，開懷暢飲，滿口生香，飲後空杯，留香更大，持久不散。 口味幽雅細膩。 酒體豐滿醇厚，回味悠長，香味不絕。 華康酒液具有純淨透明、醇馥幽鬱的特點。',
    hkWineDetail3: '隨著文化創意產品的蓬勃發展，文化創意已經成為未來提升設計價值的關鍵所在，將“文化”融入產品的設計已經是全球市場的設計趨勢，華康酒帶著美好的寓意，將中華文化融入酒文化裏，共同參與文創產品的發展與文宣，在大家品鑒、欣賞的同時，以酒結友。',
    hkWineDetail4: '華康酒經典款，以中國的傳統紅色為主打色，瓶身流暢一體，文字和包裝均以燙金工藝突出產品特點，線條感豐富，翻蓋式的打開管道，更為時尚，符合福斯審美特點。 其價格親民，性價比極高，作為主打經典款。',
    // 招商加盟
    ContactUs: "聯繫我們",
    copyright:'©2022 版權所有華康集團 豫ICP備 2022023872号-2號',
    Tips:'您的瀏覽器不支持video標籤。',
}
export default zhTW