import VueRouter from "vue-router"
const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: {
                name: 'home'
            }
        },
        //配置路由的路径
        {
            path: '/home',
            name: 'home',
            redirect: {
                name: "firstPage"
            },
            components: {
                default: () => import('@/views/home/index.vue'),
            },
            children: [
                {
                    path: 'first',
                    name: 'firstPage',
                    components: {
                        default: () => import('@/views/firstPage/index.vue')
                    }
                },
                {
                    path: 'second',
                    name: 'secondPage',
                    components: {
                        default: () => import('@/views/secondPage/index.vue')
                    }
                },
                {
                    path: 'third',
                    name: 'thirdPage',
                    components: {
                        default: () => import('@/views/thirdPage/index.vue')
                    }
                },
                {
                    path: 'four',
                    name: 'fourPage',
                    components: {
                        default: () => import('@/views/fourPage/index.vue')
                    }
                },
            ]
        },
    ]
})

// 解决跳转重复路由报错问题
const originalPush = router.push
router.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
export default router;